import React from "react"
import { ArticleImage } from "../../../entities/ArticleEntity"
import { Image as ImageResolver } from "../../Image/Image"

export const Image: React.FC<ArticleImage> = props => {
  return (
    <div className="px-4 md:px-0 md:max-w-3xl my-2 mx-auto">
      <div className="max-w-3xl ring-8 ring-pink-100 mx-auto overflow-hidden rounded-lg safari-rounded-lg">
        <ImageResolver className="w-full" src={props.src} alt={props.alt} />
      </div>
      <p className="text-slate-400 text-base font-medium md:text-lg mt-2">
        {props.legend}
      </p>
    </div>
  )
}
